import * as React from "react"
import { graphql, navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"
import Layout from "../components/Layout"
import Section from "../components/Homepage/Section"

const Index = ({ data: { contentfulHomePage, contentfulFooter } }) => {
  let logged = isLoggedIn();
  if (!logged) {
    console.warn("Not logged in");
    navigate("/user/auth")
    return null
  }

  const homepage = contentfulHomePage;
  let extendedFooter = { ...contentfulFooter, "dateOfPreparation": contentfulHomePage.dateOfPreparation }

  return (
    logged && (
      <>
        <Layout pageClass={contentfulHomePage.locale} footer={extendedFooter} locale={contentfulHomePage.locale}>
          <div className="left-container">
            <div className="inner">
              <div className="header home-header">
                <h1 dangerouslySetInnerHTML={{ __html: homepage.header.title }}></h1>
                <p dangerouslySetInnerHTML={{ __html: homepage.header.subtitle?.subtitle }}></p>
              </div>

              {homepage.leftSections && (
                <>
                  {homepage.leftSections.map((item, index) => {
                    return (
                      <Section
                        key={index}
                        sectionClass={item.class}
                        content={item.content}
                      />
                    )
                  })}
                </>
              )}
            </div>
          </div>
          <div className="right-container">
            <div className="inner">
              {homepage.rightSections && (
                <>
                  {homepage.rightSections.map((item, index) => {
                    return (
                      <Section
                        key={index}
                        sectionClass={item.class}
                        content={item.content}
                      />
                    )
                  })}
                </>
              )}
            </div>
          </div>
        </Layout>
      </>
    )
  )
}

export const data = graphql`
  query HomePageQuery($locale: String) {
    contentfulHomePage(locale: {eq: $locale}) {
      dateOfPreparation
      locale
      header {
        class
        title
        subtitle{
          subtitle
        }
      }
      leftSections {
        class
        content {
          childMdx {
            body
          }
        }
      }
      rightSections {
        class
        content {
          childMdx {
            body
          }
        }
      }
    }
    contentfulFooter(type: {eq: "home"}, locale: {eq: $locale}) {
      locale
      type
      refs{
        refs
      }
      disclaimer {
        disclaimer
      }
    }
  }
`

export default Index
