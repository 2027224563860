import React from "react"
import Markdown from "../Markdown"

const Section = ({ sectionClass, content }) => {

  return (
    <div className={`section ${sectionClass}`}>
      {content.childMdx && <Markdown>{content.childMdx.body}</Markdown>}
    </div>
  )
}

export default Section
